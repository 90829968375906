import {
  Autocomplete,
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import axios from "../axios";
import dayjs from "dayjs";
import { useCallback, useMemo, useRef, useState } from "react";
import { useQuery } from "react-query";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { Download, Print } from "@mui/icons-material";
import logo from "./images/logo.png";
import { useReactToPrint } from "react-to-print";

const PaymentsReport = () => {
  const [startDate, setStartDate] = useState(dayjs());
  const [endDate, setEndDate] = useState(dayjs());
  const [selectedStudent, setSelectedStudent] = useState(null);

  const [selectedReceipt, setSelectedReceipt] = useState(null);
  const printRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const { data: receipts, isLoading } = useQuery(
    [
      "reports",
      startDate.toISOString(),
      endDate.toISOString(),
      selectedStudent?.id,
    ],
    () => {
      if (!startDate.isValid() || !endDate.isValid()) return;
      return axios("/reports", {
        params: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          studentId: selectedStudent?.id,
        },
      }).then((res) => res.data);
    }
  );

  const { data: students } = useQuery("students", () =>
    axios("/students").then((res) => res.data)
  );

  const getDetailPanelContent = useCallback(
    ({ row }) => (
      <Box p={4}>
        {/* <Typography variant="h5" fontWeight={500} component="h2">
          Individual Fees
        </Typography> */}
        {row.bankTransfer && (
          <>
            <Typography variant="h6" fontWeight={500} component="h3">
              Bank Transfer Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                mt: 2,
              }}
            >
              <Box>
                <Typography variant="body2" fontWeight={500} component="p">
                  Bank Name
                </Typography>
                <Typography variant="body2" component="p">
                  {row.bankTransfer.bankName}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" fontWeight={500} component="p">
                  Account Number
                </Typography>
                <Typography variant="body2" component="p">
                  {row.bankTransfer.accountNumber}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" fontWeight={500} component="p">
                  Deposit Date
                </Typography>
                <Typography variant="body2" component="p">
                  {row.bankTransfer.depositDate}
                </Typography>
              </Box>
            </Box>
          </>
        )}
        {row.cheque && (
          <>
            <Typography variant="h6" fontWeight={500} component="h3">
              Cheque Details
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                mt: 2,
              }}
            >
              <Box>
                <Typography variant="body2" fontWeight={500} component="p">
                  Bank Name
                </Typography>
                <Typography variant="body2" component="p">
                  {row.cheque.bankName}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" fontWeight={500} component="p">
                  Cheque Number
                </Typography>
                <Typography variant="body2" component="p">
                  {row.cheque.chequeNumber}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" fontWeight={500} component="p">
                  Cheque Date
                </Typography>
                <Typography variant="body2" component="p">
                  {row.cheque.chequeDate}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body2" fontWeight={500} component="p">
                  Status
                </Typography>
                <Typography variant="body2" component="p">
                  {row.cheque.status}
                </Typography>
              </Box>
            </Box>
          </>
        )}
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>Student ID</TableCell>
                <TableCell>Student Name</TableCell>
                <TableCell>Grade</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Period</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Due Amount</TableCell>
                <TableCell>Discount</TableCell>
                <TableCell align="right">Payment Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {row.receiptFees.map((fee) => (
                <TableRow
                  key={fee.fee.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{fee.fee.studentID}</TableCell>
                  <TableCell>{fee.fee.student.fullName}</TableCell>
                  <TableCell>{fee.fee.student?.class?.name}</TableCell>
                  <TableCell>{fee.fee.description}</TableCell>
                  <TableCell>{fee.fee.period}</TableCell>
                  <TableCell>{fee.fee.status}</TableCell>
                  <TableCell align="right">{fee.fee.amount}</TableCell>
                  <TableCell align="right">{fee.fee.discount}</TableCell>
                  <TableCell align="right">{fee.feeAmount}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ),
    []
  );

  const getDetailPanelHeight = useCallback(() => "auto", []);

  const printReceipt = useCallback(
    (receipt) => {
      setSelectedReceipt(receipt);
      setTimeout(() => {
        handlePrint();
      }, 500); // Allow time for the receipt to render
    },
    [handlePrint]
  );

  const reportColumns = useMemo(
    () => [
      { field: "id", headerName: "Receipt No", width: 150 },
      { field: "createdBy", headerName: "Issued By", width: 150 },
      {
        field: "issuedOn",
        headerName: "Issued On",
        width: 150,
        valueGetter: (params) =>
          dayjs(params.row.createdAt).format("DD/MM/YYYY"),
      },
      {
        field: "issuedAt",
        headerName: "Issued At",
        width: 150,
        valueGetter: (params) => dayjs(params.row.createdAt).format("HH:mm:ss"),
      },
      { field: "paymentMethod", headerName: "Payment Method", width: 150 },
      {
        field: "totalReceiptFees",
        headerName: "Receipt Total",
        width: 200,
        valueGetter: (params) => {
          const { receiptFees } = params.row;
          const total = receiptFees.reduce(
            (acc, fee) => acc + fee.feeAmount,
            0
          );
          return `Rs. ${total.toLocaleString()}`;
        },
      },
      {
        field: "action",
        headerName: "",
        width: 150,
        sortable: false,
        renderCell: (params) => (
          <Box
            sx={{
              display: "grid",
              placeItems: "center",
              width: "100%",
            }}
          >
            <Button
              sx={{ width: "100%" }}
              onClick={() => printReceipt(params.row)}
            >
              <Print />
            </Button>
          </Box>
        ),
      },
    ],
    [printReceipt]
  );

  const downloadReport = () => {
    let csvContent = "data:text/csv;charset=utf-8,";

    // Add headers
    csvContent +=
      "Receipt No, Student ID, Student Name, Grade, Description, Period, Status, Due Amount, Discount, Payment Amount\n";

    // Add data
    receipts.forEach((receipt) => {
      receipt.receiptFees.forEach((fee) => {
        csvContent += `${receipt.id}, ${fee.fee.studentID}, ${fee.fee.student.fullName}, ${fee.fee.student?.class?.name}, ${fee.fee.description}, ${fee.fee.period}, ${fee.fee.status}, ${fee.fee.amount}, ${fee.fee.discount}, ${fee.feeAmount}\n`;
      });
    });
    // Add total
    csvContent += `Total, , , , , , , , , ${receipts
      ?.flatMap((receipt) => receipt.receiptFees)
      .reduce((acc, fee) => acc + fee.feeAmount, 0)}\n`;

    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute(
      "download",
      `payments_report_${startDate.format("DDMMYYYY")}_${endDate.format(
        "DDMMYYYY"
      )}.csv`
    );
    document.body.appendChild(link);
    link.click();
  };

  return (
    <Box>
      <Typography variant="h5" component="h2">
        Payments Report
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: "1rem",
          alignItems: "center",
          mt: 2,
        }}
      >
        <Autocomplete
          sx={{ width: 500, mt: 3 }}
          options={students || []}
          onChange={(_, student) => setSelectedStudent(student)}
          getOptionLabel={(student) => `${student.id} | ${student.fullName}`}
          renderInput={(params) => (
            <TextField {...params} label="Student name" />
          )}
        />

        <Box>
          <Typography>Date Range</Typography>
          <Box
            sx={{
              display: "flex",
              gap: "1rem",
              alignItems: "center",
            }}
          >
            <DatePicker
              inputFormat="DD/MM/YYYY"
              disableFuture
              value={startDate}
              onChange={(newValue) => {
                if (!newValue.isValid()) return;
                setStartDate(newValue);
                if (newValue.isAfter(endDate)) {
                  setEndDate(newValue);
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
            <Typography>-</Typography>
            <DatePicker
              inputFormat="DD/MM/YYYY"
              disableFuture
              value={endDate}
              onChange={(newValue) => {
                if (!newValue.isValid()) return;
                setEndDate(newValue);
                if (newValue.isBefore(startDate)) {
                  setStartDate(newValue);
                }
              }}
              renderInput={(params) => <TextField {...params} />}
            />
          </Box>
        </Box>

        <Button onClick={downloadReport} variant="outlined" color="primary">
          Download <Download />
        </Button>
      </Box>

      <DataGridPro
        sx={{ mt: 4 }}
        density="compact"
        rows={receipts || []}
        columns={reportColumns}
        autoHeight
        disableSelectionOnClick
        loading={isLoading}
        sortModel={[
          {
            field: "issuedOn",
            sort: "desc",
          },
          {
            field: "issuedAt",
            sort: "desc",
          },
        ]}
        slots={{
          // toolbar: GridToolbar,
          footer: () => (
            <Box
              sx={{
                my: 2,
                mr: 2,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Typography variant="body2" fontWeight={500} component="p">
                Total:
              </Typography>
              <Typography variant="body2" fontWeight={400} component="p">
                Rs.{" "}
                {receipts
                  ? receipts
                      ?.flatMap((receipt) => receipt.receiptFees)
                      .reduce((acc, fee) => acc + fee.feeAmount, 0)
                      .toLocaleString()
                  : 0}
              </Typography>
            </Box>
          ),
        }}
        getDetailPanelHeight={getDetailPanelHeight}
        getDetailPanelContent={getDetailPanelContent}
      />

      {/* Render Receipt */}
      {selectedReceipt && (
        <Box ref={printRef} className="print">
          {/* receipt info */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img src={logo} alt="logo" width={110} />
            <Box>
              <Typography
                variant="h5"
                fontWeight={600}
                component="h1"
                align="center"
              >
                Mount Royal International School
              </Typography>
              <Typography variant="body1" component="p" align="center">
                No 67, Kawdana Road, Dehiwala.
              </Typography>
              <Typography variant="body1" component="p" align="center">
                0112712149
              </Typography>
            </Box>
            <Box>
              <Typography variant="h6" fontWeight={600} component="h2">
                Payment Receipt
              </Typography>
              <Typography variant="body2" component="p" align="right">
                {dayjs(selectedReceipt.createdAt).format("DD-MM-YYYY hh:mm A")}
              </Typography>
              <Typography variant="body2" component="p" align="right">
                Payment Method: {selectedReceipt.paymentMethod}
              </Typography>
              <Typography variant="body2" component="p" align="right">
                Receipt No: {selectedReceipt.id}
              </Typography>
            </Box>
          </Box>

          <Box mb={2}>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small">
                {/* table heading */}
                <TableHead>
                  <TableRow>
                    <TableCell>Student ID</TableCell>
                    <TableCell>Student Name</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Period</TableCell>
                    <TableCell align="right">Total</TableCell>
                    <TableCell align="right">Discount</TableCell>
                    <TableCell align="right">Payment Amount</TableCell>
                  </TableRow>
                </TableHead>

                {/* table content */}
                <TableBody>
                  {selectedReceipt.receiptFees.map((fee) => (
                    <TableRow key={fee.fee.id}>
                      <TableCell component="th" scope="row">
                        {fee.fee.studentID}
                      </TableCell>
                      <TableCell>{fee.fee.student.fullName}</TableCell>
                      <TableCell>{fee.fee.description}</TableCell>
                      <TableCell>{fee.fee.period}</TableCell>
                      <TableCell align="right">{fee.fee.amount}</TableCell>
                      <TableCell align="right">{fee.fee.discount}</TableCell>
                      <TableCell align="right">{fee.feeAmount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography>Collected By: {selectedReceipt.createdBy}</Typography>
              <Typography>Signature:</Typography>
            </Box>

            <Box>
              <Typography align="right">
                Gross Total: Rs.{" "}
                {selectedReceipt.receiptFees.reduce(
                  (acc, fee) => acc + fee.fee.amount,
                  0
                )}
                .00
              </Typography>
              <Typography align="right">
                Discount: Rs.{" "}
                {selectedReceipt.receiptFees.reduce(
                  (acc, fee) => acc + fee.fee.discount,
                  0
                )}
                .00
              </Typography>
              <Typography align="right">
                Net Total: Rs.{" "}
                {selectedReceipt.receiptFees.reduce(
                  (acc, fee) => acc + fee.feeAmount,
                  0
                )}
                .00
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PaymentsReport;
