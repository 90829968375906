import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
// import axios from "../axios";
import { useState } from "react";
// import { useQuery } from "react-query";

const classIds = {
  Nursery: 1,
  Reception: 2,
  "Pre Grade": 4,
  "Grade 1": 6,
  "Grade 2": 8,
  "Grade 3": 10,
  "Grade 4": 12,
  "Grade 5": 14,
  "Grade 6": 16,
  "Grade 7": 18,
  "Grade 8": 20,
  "Grade 9": 22,
  "Grade 10": 24,
  "Grade 11": 26,
};

const ClassReport = () => {
  const [classGroup, setClassGroup] = useState(1);

  const handleChange = (event) => {
    setClassGroup(event.target.value);
  };

  // const { data: classReport, isLoading } = useQuery(
  //   ["classReport", classGroup],
  //   () =>
  //     axios("/reports/class", {
  //       params: {
  //         classId: classGroup,
  //       },
  //     }).then((res) => res.data)
  // );

  return (
    <Box>
      <Typography variant="h5" component="h2">
        Class Report
      </Typography>
      <Box
        sx={{
          maxWidth: 120,
        }}
      >
        <FormControl fullWidth>
          <InputLabel id="select-label">Class</InputLabel>
          <Select
            labelId="select-label"
            id="class-select"
            value={classGroup}
            label="Class"
            onChange={handleChange}
          >
            {Object.keys(classIds).map((key) => (
              <MenuItem key={key} value={classIds[key]}>
                {key}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </Box>
  );
};

export default ClassReport;
