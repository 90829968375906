import axios from "./axios";
import { useQuery } from "react-query";
import Login from "./pages/Login";
import AuthenticatedApp from "./AuthenticatedApp";

export default function App() {
  const { data: user, isLoading } = useQuery(
    "user",
    () => axios.get("/auth/me").then((res) => res.data.user),
    {
      staleTime: 1000 * 60 * 60 * 24, // 1 day - refetch after 1 day if the user is still logged in
    }
  );

  if (isLoading) return <div>Loading..</div>;
  return user ? <AuthenticatedApp /> : <Login />;
}

// TODO: refetch when the user logs out
