import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControlLabel,
  Paper,
  Popover,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import axios from "../axios";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Controller, useForm } from "react-hook-form";
import dayjs from "dayjs";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { useReactToPrint } from "react-to-print";
import logo from "./images/logo.png";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { queryClient } from "../index.js";

const columns = [
  { field: "description", headerName: "Description", width: 140 },
  { field: "period", headerName: "Period", width: 200 },
  { field: "amount", headerName: "Amount" },
  { field: "settledAmount", headerName: "Settled" },
  {
    field: "balance",
    headerName: "Balance",
    valueGetter: (params) => {
      return params.row.amount - params.row.settledAmount;
    },
  },
  { field: "status", headerName: "Status", width: 170 },
];

const invoiceColumns = [
  { field: "description", headerName: "Description", width: 140 },
  { field: "period", headerName: "Period", width: 200 },
  { field: "amount", headerName: "Amount" },
  { field: "settledAmount", headerName: "Settled" },
  {
    field: "balance",
    headerName: "Balance",
    valueGetter: (params) => {
      return params.row.amount - params.row.settledAmount;
    },
  },
  {
    field: "discount",
    headerName: "Discount %",
    editable: true,
    headerClassName: "editable",
    type: "number",
    valueFormatter: (params) => `${params.value} %`,
  },
  {
    field: "discountAmount",
    headerName: "Discount Amount",
    width: 150,
    type: "number",
    editable: true,
    headerClassName: "editable",
  },
  {
    field: "paymentAmount",
    headerName: "Payment Amount",
    type: "number",
    editable: true,
    headerClassName: "editable",
    width: 140,
  },
  { field: "status", headerName: "Status", width: 170 },
];

const NewPayment = () => {
  // for printing
  const printRef = useRef(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
  });

  const user = queryClient.getQueryData("user");
  const [selectedFees, setSelectedFees] = useState({}); // selected fees from the table
  const [invoiceFees, setInvoiceFees] = useState([]); // same as selectedFees but with discount and payment amount.
  const [receiptFees, setReceiptFees] = useState({}); // grouped fees by student [studentID: {student: {}, fees: []}
  const [parentID, setParentID] = useState(0);
  const [receiptNumber, setReceiptNumber] = useState(0);
  const [defaultDate, setDefaultDate] = useState(dayjs());
  const navigate = useNavigate();

  const { data: students } = useQuery("students", () =>
    axios("/students").then((res) => res.data)
  );

  // fetch siblings fees when a student is selected
  const {
    data: siblingsFees,
    isFetching,
    refetch: refetchSiblings,
  } = useQuery(
    ["siblingsFees", parentID],
    () =>
      axios("/fees/siblings-fees", {
        params: {
          parentID: parentID,
        },
      }).then((res) => res.data),
    {
      enabled: false,
    }
  );

  // submit payment
  const addPaymentMutation = useMutation((data) => {
    return axios.put("/fees", data);
  });

  const { register, handleSubmit, control, watch, setValue } = useForm({
    defaultValues: {
      paymentMethod: "cash",
    },
  });

  const handleFormSubmission = (data) => {
    handlePopoverClose();
    if (Object.keys(selectedFees).length === 0) {
      toast.error("Please select at least one fee");
      return;
    }

    // group invoice fees by student for printing
    let groupedFees = {};
    for (const [studentID, fee] of Object.entries(selectedFees)) {
      if (!groupedFees[studentID]) {
        // get student details
        const student = students.find(
          (student) => student.id === parseInt(studentID)
        );
        groupedFees = {
          ...groupedFees,
          [studentID]: {
            student: student,
            fees: fee,
          },
        };
      } else {
        groupedFees = {
          ...groupedFees,
          [studentID]: {
            ...groupedFees[studentID],
            fees: [...groupedFees[studentID].fees, fee],
          },
        };
      }
    }
    setReceiptFees(groupedFees);

    // submit payment
    addPaymentMutation.mutate(
      {
        selectedFees,
        chequeDetails: {
          bankName: data.bankName,
          chequeDate: data.chequeDate,
          chequeNumber: data.chequeNumber,
        },
        bankTransferDetails: {
          bankName: data.bankName,
          accountNumber: data.accountNumber,
          depositDate: data.depositDate,
        },
        paymentMethod: data.paymentMethod,
      },
      {
        onSuccess: ({ data }) => {
          setReceiptNumber(data.receiptID);
          toast.success("Payment added successfully");
          // handlePrint(); print after receipt ID is updated (useEffect)
        },
        onError: (error) => {
          toast.error("Failed to add payment");
        },
      }
    );
  };

  // print receipt after receipt number is updated
  useEffect(() => {
    if (receiptNumber !== 0) {
      handlePrint();
      navigate("/payments");
      setReceiptNumber(0);
    }
  }, [receiptNumber, handlePrint, navigate]);

  const handleDateChange = (newValue) => {
    setDefaultDate(newValue);
    setValue("chequeDate", newValue.format("DD-MM-YYYY")); // https://day.js.org/docs/en/parse/string-format
  };

  // fetch siblings fees when a student is selected
  useEffect(() => {
    if (parentID !== 0) {
      refetchSiblings();
    }
  }, [parentID, refetchSiblings]);

  // update invoice fees when selected fees are updated
  useEffect(() => {
    let temp = [];
    for (const [, fees] of Object.entries(selectedFees)) {
      temp = [...temp, ...fees];
    }
    setInvoiceFees(temp);
  }, [selectedFees]);

  const fetchFees = (e, value) => {
    if (value) {
      setParentID(value.parentID);
    }
  };

  // confirm button popover
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      <Typography mb={2} variant="h5" component="h2">
        New Payment
      </Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(2, 1fr)",
          gap: 2,
        }}
        autoComplete="off"
      >
        <Autocomplete
          options={students || []}
          onChange={fetchFees}
          getOptionLabel={(student) => `${student.id} | ${student.fullName}`}
          renderInput={(params) => (
            <TextField {...params} label="Student name" />
          )}
        />
      </Box>
      {parentID !== 0 && (
        <Typography mt={4} mb={2} variant="h5" component="h2">
          Due payments
        </Typography>
      )}
      {isFetching && <CircularProgress />}
      {siblingsFees &&
        siblingsFees.map(({ student, fees }) => (
          <div key={student.id}>
            <Typography mt={2} variant="h6" fontWeight={600} component="h2">
              {student.fullName}
            </Typography>
            <Box sx={{ height: 400, width: "100%" }}>
              <DataGrid
                onSelectionModelChange={(ids) => {
                  const selectedRowsData = ids.map((id) =>
                    fees.find((fee) => fee.id === id)
                  );
                  setSelectedFees({
                    ...selectedFees,
                    [student.id]: selectedRowsData,
                  });
                }}
                enableMultipleSelection
                rows={fees || []}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
              />
            </Box>
          </div>
        ))}

      {invoiceFees.length > 0 && (
        <>
          {/* Invoice preview */}
          <Typography mt={4} mb={2} variant="h5" component="h2">
            Invoice
          </Typography>
          <Box
            sx={{
              height: 400,
              width: "100%",
              "& .editable": {
                backgroundColor: "rgba(0, 0, 0, 0.15)",
              },
            }}
          >
            <DataGrid
              onCellEditCommit={(params) => {
                if (params.field === "discount") {
                  const updatedFees = invoiceFees.map((fee) => {
                    if (fee.id === params.id) {
                      fee.discount = params.value;
                      fee.discountAmount = (fee.discount * fee.amount) / 100;
                      fee.paymentAmount =
                        fee.amount - fee.settledAmount - fee.discountAmount;
                    }
                    return fee;
                  });
                  setInvoiceFees(updatedFees);
                } else if (params.field === "discountAmount") {
                  const updatedFees = invoiceFees.map((fee) => {
                    if (fee.id === params.id) {
                      fee.discountAmount = params.value;
                      fee.paymentAmount =
                        fee.amount - fee.settledAmount - fee.discountAmount;
                      fee.discount = (
                        (fee.discountAmount / fee.amount) *
                        100
                      ).toFixed(2);
                    }
                    return fee;
                  });
                  setInvoiceFees(updatedFees);
                } else if (params.field === "paymentAmount") {
                  const updatedFees = invoiceFees.map((fee) => {
                    if (fee.id === params.id) {
                      fee.paymentAmount = params.value;
                    }
                    return fee;
                  });
                  setInvoiceFees(updatedFees);
                }
              }}
              rows={invoiceFees || []}
              columns={invoiceColumns}
              disableSelectionOnClick
              pageSize={5}
              rowsPerPageOptions={[5]}
            />
          </Box>
        </>
      )}

      {invoiceFees.length > 0 && (
        <>
          <Typography mt={4} mb={2} variant="h5" component="h2">
            Payment method
          </Typography>

          <Typography sx={{ mb: 1 }}>
            Total: Rs.{" "}
            {invoiceFees.reduce((acc, fee) => acc + fee.paymentAmount, 0)}
          </Typography>

          {/* Payment method selection */}
          <Box component="form">
            <Controller
              control={control}
              name="paymentMethod"
              render={({ field }) => (
                <RadioGroup {...field}>
                  <FormControlLabel
                    value="cash"
                    control={<Radio />}
                    label="Cash"
                  />
                  <FormControlLabel
                    value="cheque"
                    control={<Radio />}
                    label="Cheque"
                  />
                  <FormControlLabel
                    value="bankTransfer"
                    control={<Radio />}
                    label="Bank Transfer"
                  />
                </RadioGroup>
              )}
            />
            {watch("paymentMethod") === "bankTransfer" && (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 2,
                  marginTop: 1,
                }}
              >
                <TextField
                  name="accountNumber"
                  label="Account number"
                  control={control}
                  required
                  {...register("accountNumber")}
                />
                <TextField
                  name="bankName"
                  label="Bank name"
                  control={control}
                  required
                  {...register("bankName")}
                />
                <DesktopDatePicker
                  label="Deposit date"
                  inputFormat="DD-MM-YYYY"
                  value={defaultDate}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      {...register("depositDate")}
                    />
                  )}
                />
              </Box>
            )}
            {watch("paymentMethod") === "cheque" && (
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(2, 1fr)",
                  gap: 2,
                  marginTop: 1,
                }}
              >
                <TextField
                  name="chequeNumber"
                  label="Cheque number"
                  control={control}
                  required
                  {...register("chequeNumber")}
                />
                <TextField
                  name="bankName"
                  label="Bank name"
                  control={control}
                  required
                  {...register("bankName")}
                />
                <DesktopDatePicker
                  label="Cheque date"
                  inputFormat="DD-MM-YYYY"
                  value={defaultDate}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      required
                      {...params}
                      {...register("chequeDate")}
                    />
                  )}
                />
              </Box>
            )}
            <Box my={2}>
              <Button
                aria-describedby={id}
                variant="contained"
                onClick={handleClick}
              >
                Add payment
              </Button>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Button
                  color="success"
                  onClick={handleSubmit(handleFormSubmission)}
                >
                  Confirm
                </Button>
              </Popover>
            </Box>
          </Box>
        </>
      )}

      {/* Receipt */}
      <Box ref={printRef} className="print">
        {/* receipt info */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="logo" width={110} />
          <Box>
            <Typography
              variant="h5"
              fontWeight={600}
              component="h1"
              align="center"
            >
              Mount Royal International School
            </Typography>
            <Typography variant="body1" component="p" align="center">
              No 67, Kawdana Road, Dehiwala.
            </Typography>
            <Typography variant="body1" component="p" align="center">
              0112712149
            </Typography>
          </Box>
          <Box>
            <Typography variant="h6" fontWeight={600} component="h2">
              Payment Receipt
            </Typography>
            <Typography variant="body2" component="p" align="right">
              {dayjs().format("DD-MM-YYYY hh:mm A")}
            </Typography>
            <Typography variant="body2" component="p" align="right">
              Payment Method:{" "}
              {watch("paymentMethod").charAt(0).toUpperCase() +
                watch("paymentMethod").slice(1)}
            </Typography>
            <Typography variant="body2" component="p" align="right">
              Receipt No: {receiptNumber}
            </Typography>
          </Box>
        </Box>

        {/* FOR EACH STUDENT CREATE A TABLE */}
        {Object.entries(receiptFees).map(([studentID, info]) => (
          // One student
          <Box key={studentID} mb={2}>
            <Typography
              component="h3"
              variant="h6"
              style={{
                fontSize: "1rem",
                marginBottom: "0.5rem",
              }}
            >
              {info.student.fullName} | {info.student.class.name}
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} size="small">
                {/* table heading */}
                <TableHead>
                  <TableRow>
                    <TableCell>Student ID</TableCell>
                    <TableCell>Description</TableCell>
                    <TableCell>Period</TableCell>
                    <TableCell align="right">Total</TableCell>
                    <TableCell align="right">Settled</TableCell>
                    <TableCell align="right">Discount</TableCell>
                    <TableCell align="right">Payment Amount</TableCell>
                  </TableRow>
                </TableHead>

                {/* table content */}
                <TableBody>
                  {info.fees.map((fee) => (
                    <TableRow key={fee.id}>
                      <TableCell component="th" scope="row">
                        {fee.studentID}
                      </TableCell>
                      <TableCell>{fee.description}</TableCell>
                      <TableCell>{fee.period}</TableCell>
                      <TableCell align="right">{fee.amount}</TableCell>
                      <TableCell align="right">{fee.settledAmount}</TableCell>
                      <TableCell align="right">{fee.discountAmount}</TableCell>
                      <TableCell align="right">{fee.paymentAmount}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        ))}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography>Collected By: {user.name}</Typography>
            <Typography>Signature:</Typography>
          </Box>

          <Box>
            <Typography align="right">
              Gross Total: Rs.{" "}
              {invoiceFees.reduce((acc, fee) => acc + fee.amount, 0)}.00
            </Typography>
            <Typography align="right">
              Discount: Rs.{" "}
              {invoiceFees.reduce((acc, fee) => acc + fee.discountAmount, 0)}.00
            </Typography>
            <Typography align="right">
              Net Total: Rs.{" "}
              {invoiceFees.reduce((acc, fee) => acc + fee.paymentAmount, 0)}.00
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default NewPayment;
